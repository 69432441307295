<!-- eslint-disable import/no-cycle -->
<script>
import VButton from '@/components/Button.vue';
import VIcon from '@/components/Icon.vue';
import VTextbox from '@/components/Textbox.vue';
import VUserAccount from '@/components/UserAccount.vue';
import VUserPassword from '@/components/UserPassword.vue';
import { cacheUserData, removeUserData } from '@/helpers/user';

import Terms from '@/components/Terms.vue';
import Conditions from '@/components/Conditions.vue';
import Consentimento from '@/components/TermsConsentimento.vue';
import CodeOfEthics from '@/components/CodeOfEthics.vue';
import Faq from '@/components/Faq.vue';

export default {
  components: {
    VButton,
    VIcon,
    VTextbox,
    VUserAccount,
    VUserPassword,
    Terms,
    Conditions,
    Consentimento,
    CodeOfEthics,
    Faq
  },
  data() {
    return {
      expanded: '',
      password: {},
      uploading: false,
      showSiteTerms: false,
      showSiteConsentimento: false,
      showSiteConditions: false,
      showCodeOfEthics: false,
      showFaqModal: false,
    };
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
  },
  methods: {
    changeAvatar(e) {
      this.uploading = true;
      const file = e.target.files[0];
      const formData = new FormData();
      formData.append('avatar', file);
      this.$http.post('/users/user-uploader-avatar', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        }
      })
        .then(({ data }) => {
          if (data.ok) {
            const img = new Image();
            img.src = data.avatar;
            img.onload = () => {
              this.uploading = false;
              this.$store.commit('setAvatar', data.avatar);
              this.$store.commit('message', { type: 'info', text: 'Imagem alterada com sucesso!' });
              removeUserData();
              cacheUserData();
            };
          }
        })
        .catch(() => {
          this.uploading = false;
          this.$store.commit('message', { type: 'error', text: 'Ocorreu um erro ao alterar a imagem. Tente novamente.' });
        });
    },
    signOut() {
      this.$store.commit('signOut');
    },
    toggle(value) {
      this.expanded = this.expanded === value ? false : value;
    },
    validatePassword() {
      const { senha: password, confirmaSenha } = this.password;
      // return (password === this.user.confirmaSenha
      //         && /[A-Z]+/.test(password) // has one capital letter
      //         && /[\w]+/.test(password) // has one letter
      //         && /\d+/.test(password) // has one number
      //         && /[\`\~\!\@\#\$\%\^\&\*\(\)\-\_\+\=\[\]\{\}\\\|\;\:\'\"\,\.\<\>\/\?]/.test(password) // special character
      //         && /^\d{8,9}$/.test(this.user.telefones.numeroTelefone));
      return (!!password
        && password === confirmaSenha
        && /[\w]+/.test(password) // has one letter
        && /\d+/.test(password) // has one number
        && /(.*?[\W_].*?){2,}/.test(password) // has two special characters
        && /[a-z]+/.test(password) // has one lowercase letter
        && /[A-Z]+/.test(password) // has one capital letter
        && password.length >= 10);
    },
    updateAccount() {
      if (!this.$store.state.formLoading) {
        this.$store.commit('formLoading', true);
        this.$validator.validateAll().then((valid) => {
          if (valid) {
            const userDocumentType = this.user.tipo === 'PF' ? 'cpf' : 'cnpj';
            // this.$http.put(`/users/update?type=${this.user.tipo}&userId=${this.user.idConductor}`, {
            this.$http.put(`/users/update?type=PF&userId=${this.user.idConductor}`, {
              avatar: this.user.avatar,
              name: this.user.nome,
              motherName: this.user.nomeMae,
              document: this.user[userDocumentType],
              birthDate: this.user.dataNascimento,
              address: {
                idAddressType: 1,
                street: this.user.enderecos.logradouro,
                number: this.user.enderecos.numero,
                zipCode: this.user.enderecos.cep,
                city: this.user.enderecos.cidade,
                neighborhood: this.user.enderecos.bairro,
                federativeUnit: this.user.enderecos.uf,
                country: 'Brasil',
                complement: this.user.enderecos.complemento,
                referencePoint: ''
              },
              phone: {
                idPhoneType: 18,
                areaCode: this.user.telefones.ddd,
                number: this.user.telefones.numeroTelefone,
              }
            })
              .then(() => {
                this.$store.commit('setUser', this.user);
                this.$store.commit('formLoading', false);
                this.$store.commit('message', { type: 'info', text: 'Dados de cadastro alterados com sucesso.' });
                this.expanded = false;
              });
          } else {
            this.$store.commit('formLoading', false);
          }
        });
      }
    },
    updatePassword() {
      if (!this.$store.state.formLoading) {
        this.$store.commit('formLoading', true);
        this.$validator.validateAll('password').then((ok) => {
          if (ok && this.validatePassword()) {
            this.$http.put('/users/user-password-updater', this.password)
              .then(() => {
                this.$store.commit('formLoading', false);
                this.$store.commit('message', { type: 'info', text: 'Senha de acesso alterada com sucesso.' });
                this.password = {};
                this.expanded = false;
              });
          } else {
            this.$store.commit('formLoading', false);
          }
        });
      }
    },
  },
  mounted() {
    // desabilitando campos que não podem ser editados
    const name = document.getElementsByName('account.name')[0]
    const birthDate = document.getElementsByName('account.birthDate')[0]
    const mothersName = document.getElementsByName('account.mothersName')[0]

    if (name) name.disabled = true
    if (birthDate) birthDate.disabled = true
    if (mothersName) mothersName.disabled = true
  },
  beforeMount() {
    cacheUserData();
  },
};
</script>

<template>
  <div class="account">
    <div class="account__user">
      <div class="account__user__avatar">
        <div :style="`background-image: url(${this.user.avatar})`" class="account__user__avatar__figure"/>
        <div v-if="uploading" class="account__user__avatar__loading">
          <v-icon class="account__user__avatar__loading__icon" name="loading"/>
        </div>
      </div>
      <label class="account__user__change" for="account__user__change__input--id">ALTERAR IMAGEM</label>
      <input
        @change="changeAvatar"
        accept="image/*"
        class="account__user__change__input"
        id="account__user__change__input--id"
        type="file"/>
    </div>
    <div class="account__content">
      <div
        :class="{ 'expanded': expanded === 'user-account' }"
        class="account__content__section">
        <div @click="toggle('user-account')" class="account__content__section__header">
          <div class="account__content__section__header__title">
            Alterar dados pessoais
          </div>
          <v-icon class="account__content__section__header__icon" name="arrow-down"/>
        </div>
        <div class="account__content__section__content">
          <form @submit.prevent="updateAccount">
            <v-user-account v-model="user" :responsive="false">
            </v-user-account>
            <v-button label="Salvar alterações" type="button" @click="updateAccount"/>
          </form>
        </div>
      </div>
      <div
        :class="{ 'expanded': expanded === 'user-password' }"
        class="account__content__section">
        <div @click="toggle('user-password')" class="account__content__section__header">
          <div class="account__content__section__header__title">
            Alterar senha de acesso
          </div>
          <v-icon class="account__content__section__header__icon" name="arrow-down"/>
        </div>
        <div class="account__content__section__content">
          <form @submit.prevent="updatePassword">
            <v-textbox
              v-model="password.senhaAtual"
              data-vv-as="Senha atual"
              data-vv-scope="password"
              class="account__content__section__content__current-password"
              label="Senha atual:"
              name="password.currentPassword"
              validate="required"
              type="password"/>
            <v-user-password :data="password" :responsive="false">
              <v-button label="Salvar alterações"/>
            </v-user-password>
          </form>
        </div>
      </div>
      <div
        :class="{ 'expanded': expanded === 'termos' }"
        class="account__content__section">
        <div @click="toggle('termos')" class="account__content__section__header">
          <div class="account__content__section__header__title">
            Política de Privacidade
          </div>
          <v-icon class="account__content__section__header__icon" name="arrow-down"/>
        </div>
        <div class="account__content__section__content">
          <br>
          <p><a class="text-terms" href="#" @click="showSiteConsentimento = true">Termo de Consentimento</a></p>
          <p><a class="text-terms" href="#" @click="showSiteTerms = true">Política de Privacidade</a></p>
          <p><a class="text-terms" href="#" @click="showSiteConditions = true">Termos e Condições de Uso</a></p>
          <!-- <p><a class="text-terms" href="#" @click="showCodeOfEthics = true">Código de Ética</a></p> -->
        </div>
      </div>
      <div
        :class="{ 'expanded': expanded === 'ajuda' }"
        class="account__content__section">
        <div @click="toggle('ajuda')" class="account__content__section__header">
          <div class="account__content__section__header__title">
            Ajuda
          </div>
          <v-icon class="account__content__section__header__icon" name="arrow-down"/>
        </div>
        <div class="account__content__section__content">
          <br>
          <p>
            <span>Se precisar fale com a gente <br /></span>
            <br />
            E-mail:
            <a href="mailto:faleconosco@onlypay.com.br" target="_blank">faleconosco@onlypay.com.br</a> <br />
            Telefone/WhatsApp: <a href="https://api.whatsapp.com/send?phone=551138424693" target="_blank">(11) 3842-4693</a>
          </p>
          <p>Perguntas Frequentes: <a style="color: var(--color-green-dark);" class="text-terms" href="#" @click="showFaqModal = true">FAQ</a></p>
        </div>
      </div>
      <div class="account__content__section">
        <div @click="signOut" class="account__content__section__header">
          <div class="account__content__section__header__title">
            Sair
          </div>
        </div>
      </div>
    </div>
    <transition name="fade">
      <terms :showBtn="false" @close="showSiteTerms = false" style="z-index:1000;" v-if="showSiteTerms"/>
    </transition>
    <transition name="fade">
      <conditions :showBtn="false"  @close="showSiteConditions = false" style="z-index:1000;" v-if="showSiteConditions"/>
    </transition>
    <transition name="fade">
      <consentimento :showBtn="false"  @close="showSiteConsentimento= false" style="z-index:1000;" v-if="showSiteConsentimento"/>
    </transition>
    <transition name="fade">
      <code-of-ethics :showBtn="false"  @close="showCodeOfEthics= false" style="z-index:1000;" v-if="showCodeOfEthics"/>
    </transition>
    <transition name="fade">
      <faq @close="showFaqModal = false" style="z-index:1000;" v-if="showFaqModal" />
    </transition>

  </div>
</template>

<style lang="postcss" scoped>
.account {
  background-color: var(--color-white);
  position: relative;
  @media screen and (max-width: 991px) {
    /* padding-top: 5vh; */
  }
  @media (min-width: 576px) {
    /* padding-top: 8.75rem; */
  }
  @media (min-width: 992px) {
    display: flex;
    flex-direction: row;
    padding: 0;
  }
  &__user {
    /* background-color: var(--color-blue-medium); */
    /* background-color: var(--color-blue-onlypay); */
    height: 2.8125rem;
    position: absolute -3.0625rem 0 auto 0;
    @media (min-width: 576px) {
      background: none;
      position: relative auto;
    }
    @media (min-width: 992px) {
      height: auto;
      margin-right: 5rem;
    }
    &__avatar {
      /* background-image: linear-gradient(to right, var(--color-blue-lighten),
      var(--color-green-light)); */
      background-image: linear-gradient(to right, var(--color-blue-lighten),
      var(--color-green-onlypay));
      border-radius: 100%;
      margin: 0 auto;
      padding: .0625rem;
      position: relative;
      size: 5.5rem;
      &__figure {
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        border: 0.125rem solid var(--color-white);
        border-radius: 100%;
        size: 5.375rem;
      }
      &__loading {
        background-color: rgba(0, 0, 0, .75);
        border-radius: 100%;
        position: absolute 3px;
        &__icon {
          animation: spin 1.2s infinite linear;
          color: var(--color-white);
          margin: -1rem -1rem 0 0;
          position: absolute 50% 50% auto auto;
          size: 2rem;
        }
      }
    }
    &__change {
      color: var(--color-green-dark);
      cursor: pointer;
      display: block;
      font-size: .75rem;
      font-weight: bold;
      margin-top: .5rem;
      text-align: center;
      &__input {
        display: hidden;
      }
    }
  }
  &__content {
    padding-top: 75px;
    @media (min-width: 576px) {
      padding-top: 6rem;
    }
    @media (min-width: 992px) {
      flex-grow: 1;
      padding-top: 0;
    }
    &__section {
      /* border: .0625rem solid #dde1e8; */
      border: .0625rem solid var(--color-blue-onlypay);
      border-radius: .25rem;
      margin-bottom: 1.5rem;
      transition: 0.3s;
      &:not(.expanded):hover {
        box-shadow: 0 0 5px 0 rgba(0, 0, 0, .2);
      }
      &.expanded {
        ^&__header__icon {
          transform: rotate(-180deg);
        }
        ^&__content {
          display: block;
        }
      }
      &__header {
        cursor: pointer;
        padding: 1.5rem 1.5rem 1.25rem;
        position: relative;
        &__title {
          color: var(--color-blue-onlypay);
        }
        &__icon {
          fill: var(--color-blue-onlypay);
          margin-top: -.375rem;
          position: absolute 50% 1.5rem auto auto;
          size: 1rem;
          transition: all 250ms;
        }
      }
      &__content {
        /* border-top: .0625rem solid #dde1e8; */
        border-top: .0625rem solid var(--color-blue-onlypay);
        display: none;
        padding: 0 1.5rem 1.25rem;
        &__current-password {
          margin-top: 1.5rem;
          &:not(:last-of-type) {
            margin-bottom: 0;
          }
        }
      }
    }
  }
  .text-terms {
    color: #000b17;
  }
}
</style>
