<!-- eslint-disable -->
<script>
import VButton from '@/components/Button.vue';
import VIcon from '@/components/Icon.vue';

export default {
  name: 'Consentimento',
  components: {
    VButton,
    VIcon,
    currentPage: 0,
		pageCount: 0,
  },
  props: {
    showBtn: {
      default: true,
      type: Boolean
    },
    showCloseBtn: {
      default: true,
      type: Boolean
    }
  },
  data() {
    return {
      numPages: null
    };
  }
}
</script>
<!-- eslint-disable -->
<template>
  <div class="wrap-term">
    <div v-if="showCloseBtn" class="back-drop" @click="$emit('close')"></div>
    <div class="content">
      <a v-if="showCloseBtn" @click.prevent="$emit('close')" class="block_close">
        <v-icon class="" name="close" />
      </a>
      <p class="c14">
        <span class="c13">
        </span>
      </p>

      <!-- <pdf
        v-for="i in numPages"
        :key="i"
        :src="src"
        :page="i"
      ></pdf> -->
      <!-- show file image in folder src/files/termos-de-consentimento -->
      <div style="width: 98vw;">
        <img
          v-for="i in 6"
          :key="i" :src="`/img/termos-de-consentimento/termos-de-consentimento_page-00${(i).toString().padStart(2, 0)}.jpg`"
          width="100%"
          height="100%"
          />
      </div>

      <p class="c3"><span class="c0"></span></p>
      <p class="c3"><span class="c0"></span></p>
      <div class="button-accept" v-show="showBtn !== false">
        <v-button @click="$emit('accept')" class="" label="Li e aceito os termos de uso e política de privacidade" />
      </div>
    </div>
  </div>
</template>

<style scoped>
  .table-description {
    border: 1px #000 solid;
    padding: 5px;
    text-align: center;
  }

  .wrap-term {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    z-index: 10;
  }

  .wrap-term .back-drop {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.5);
  }

  .block_close {
    width: 20px;
    color: #000;
    height: 20px;
    position: absolute;
    right: 15px;
    top: 15px;
  }

  .block_close:hover {
    color: var(--color-green);
  }

  .wrap-term .content {
    padding: 25px 0;
    background-color: #fff;
    /* max-width: 768px; */
    /* margin: 20px auto; */
    width: 100%;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    position: relative;
    z-index: 2;
  }

  .no-margin-left {
    margin-left: 0 !important;
  }

  .inside-list {
    margin-left: 10px;
  }

  .inside-list-content {
    margin-left: 60px;
  }

</style>
