var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "account" },
    [
      _c("div", { staticClass: "account__user" }, [
        _c("div", { staticClass: "account__user__avatar" }, [
          _c("div", {
            staticClass: "account__user__avatar__figure",
            style: `background-image: url(${this.user.avatar})`,
          }),
          _vm.uploading
            ? _c(
                "div",
                { staticClass: "account__user__avatar__loading" },
                [
                  _c("v-icon", {
                    staticClass: "account__user__avatar__loading__icon",
                    attrs: { name: "loading" },
                  }),
                ],
                1
              )
            : _vm._e(),
        ]),
        _c(
          "label",
          {
            staticClass: "account__user__change",
            attrs: { for: "account__user__change__input--id" },
          },
          [_vm._v("ALTERAR IMAGEM")]
        ),
        _c("input", {
          staticClass: "account__user__change__input",
          attrs: {
            accept: "image/*",
            id: "account__user__change__input--id",
            type: "file",
          },
          on: { change: _vm.changeAvatar },
        }),
      ]),
      _c("div", { staticClass: "account__content" }, [
        _c(
          "div",
          {
            staticClass: "account__content__section",
            class: { expanded: _vm.expanded === "user-account" },
          },
          [
            _c(
              "div",
              {
                staticClass: "account__content__section__header",
                on: {
                  click: function ($event) {
                    return _vm.toggle("user-account")
                  },
                },
              },
              [
                _c(
                  "div",
                  { staticClass: "account__content__section__header__title" },
                  [_vm._v("\n          Alterar dados pessoais\n        ")]
                ),
                _c("v-icon", {
                  staticClass: "account__content__section__header__icon",
                  attrs: { name: "arrow-down" },
                }),
              ],
              1
            ),
            _c("div", { staticClass: "account__content__section__content" }, [
              _c(
                "form",
                {
                  on: {
                    submit: function ($event) {
                      $event.preventDefault()
                      return _vm.updateAccount.apply(null, arguments)
                    },
                  },
                },
                [
                  _c("v-user-account", {
                    attrs: { responsive: false },
                    model: {
                      value: _vm.user,
                      callback: function ($$v) {
                        _vm.user = $$v
                      },
                      expression: "user",
                    },
                  }),
                  _c("v-button", {
                    attrs: { label: "Salvar alterações", type: "button" },
                    on: { click: _vm.updateAccount },
                  }),
                ],
                1
              ),
            ]),
          ]
        ),
        _c(
          "div",
          {
            staticClass: "account__content__section",
            class: { expanded: _vm.expanded === "user-password" },
          },
          [
            _c(
              "div",
              {
                staticClass: "account__content__section__header",
                on: {
                  click: function ($event) {
                    return _vm.toggle("user-password")
                  },
                },
              },
              [
                _c(
                  "div",
                  { staticClass: "account__content__section__header__title" },
                  [_vm._v("\n          Alterar senha de acesso\n        ")]
                ),
                _c("v-icon", {
                  staticClass: "account__content__section__header__icon",
                  attrs: { name: "arrow-down" },
                }),
              ],
              1
            ),
            _c("div", { staticClass: "account__content__section__content" }, [
              _c(
                "form",
                {
                  on: {
                    submit: function ($event) {
                      $event.preventDefault()
                      return _vm.updatePassword.apply(null, arguments)
                    },
                  },
                },
                [
                  _c("v-textbox", {
                    staticClass:
                      "account__content__section__content__current-password",
                    attrs: {
                      "data-vv-as": "Senha atual",
                      "data-vv-scope": "password",
                      label: "Senha atual:",
                      name: "password.currentPassword",
                      validate: "required",
                      type: "password",
                    },
                    model: {
                      value: _vm.password.senhaAtual,
                      callback: function ($$v) {
                        _vm.$set(_vm.password, "senhaAtual", $$v)
                      },
                      expression: "password.senhaAtual",
                    },
                  }),
                  _c(
                    "v-user-password",
                    { attrs: { data: _vm.password, responsive: false } },
                    [_c("v-button", { attrs: { label: "Salvar alterações" } })],
                    1
                  ),
                ],
                1
              ),
            ]),
          ]
        ),
        _c(
          "div",
          {
            staticClass: "account__content__section",
            class: { expanded: _vm.expanded === "termos" },
          },
          [
            _c(
              "div",
              {
                staticClass: "account__content__section__header",
                on: {
                  click: function ($event) {
                    return _vm.toggle("termos")
                  },
                },
              },
              [
                _c(
                  "div",
                  { staticClass: "account__content__section__header__title" },
                  [_vm._v("\n          Política de Privacidade\n        ")]
                ),
                _c("v-icon", {
                  staticClass: "account__content__section__header__icon",
                  attrs: { name: "arrow-down" },
                }),
              ],
              1
            ),
            _c("div", { staticClass: "account__content__section__content" }, [
              _c("br"),
              _c("p", [
                _c(
                  "a",
                  {
                    staticClass: "text-terms",
                    attrs: { href: "#" },
                    on: {
                      click: function ($event) {
                        _vm.showSiteConsentimento = true
                      },
                    },
                  },
                  [_vm._v("Termo de Consentimento")]
                ),
              ]),
              _c("p", [
                _c(
                  "a",
                  {
                    staticClass: "text-terms",
                    attrs: { href: "#" },
                    on: {
                      click: function ($event) {
                        _vm.showSiteTerms = true
                      },
                    },
                  },
                  [_vm._v("Política de Privacidade")]
                ),
              ]),
              _c("p", [
                _c(
                  "a",
                  {
                    staticClass: "text-terms",
                    attrs: { href: "#" },
                    on: {
                      click: function ($event) {
                        _vm.showSiteConditions = true
                      },
                    },
                  },
                  [_vm._v("Termos e Condições de Uso")]
                ),
              ]),
            ]),
          ]
        ),
        _c(
          "div",
          {
            staticClass: "account__content__section",
            class: { expanded: _vm.expanded === "ajuda" },
          },
          [
            _c(
              "div",
              {
                staticClass: "account__content__section__header",
                on: {
                  click: function ($event) {
                    return _vm.toggle("ajuda")
                  },
                },
              },
              [
                _c(
                  "div",
                  { staticClass: "account__content__section__header__title" },
                  [_vm._v("\n          Ajuda\n        ")]
                ),
                _c("v-icon", {
                  staticClass: "account__content__section__header__icon",
                  attrs: { name: "arrow-down" },
                }),
              ],
              1
            ),
            _c("div", { staticClass: "account__content__section__content" }, [
              _c("br"),
              _vm._m(0),
              _c("p", [
                _vm._v("Perguntas Frequentes: "),
                _c(
                  "a",
                  {
                    staticClass: "text-terms",
                    staticStyle: { color: "var(--color-green-dark)" },
                    attrs: { href: "#" },
                    on: {
                      click: function ($event) {
                        _vm.showFaqModal = true
                      },
                    },
                  },
                  [_vm._v("FAQ")]
                ),
              ]),
            ]),
          ]
        ),
        _c("div", { staticClass: "account__content__section" }, [
          _c(
            "div",
            {
              staticClass: "account__content__section__header",
              on: { click: _vm.signOut },
            },
            [
              _c(
                "div",
                { staticClass: "account__content__section__header__title" },
                [_vm._v("\n          Sair\n        ")]
              ),
            ]
          ),
        ]),
      ]),
      _c(
        "transition",
        { attrs: { name: "fade" } },
        [
          _vm.showSiteTerms
            ? _c("terms", {
                staticStyle: { "z-index": "1000" },
                attrs: { showBtn: false },
                on: {
                  close: function ($event) {
                    _vm.showSiteTerms = false
                  },
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "transition",
        { attrs: { name: "fade" } },
        [
          _vm.showSiteConditions
            ? _c("conditions", {
                staticStyle: { "z-index": "1000" },
                attrs: { showBtn: false },
                on: {
                  close: function ($event) {
                    _vm.showSiteConditions = false
                  },
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "transition",
        { attrs: { name: "fade" } },
        [
          _vm.showSiteConsentimento
            ? _c("consentimento", {
                staticStyle: { "z-index": "1000" },
                attrs: { showBtn: false },
                on: {
                  close: function ($event) {
                    _vm.showSiteConsentimento = false
                  },
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "transition",
        { attrs: { name: "fade" } },
        [
          _vm.showCodeOfEthics
            ? _c("code-of-ethics", {
                staticStyle: { "z-index": "1000" },
                attrs: { showBtn: false },
                on: {
                  close: function ($event) {
                    _vm.showCodeOfEthics = false
                  },
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "transition",
        { attrs: { name: "fade" } },
        [
          _vm.showFaqModal
            ? _c("faq", {
                staticStyle: { "z-index": "1000" },
                on: {
                  close: function ($event) {
                    _vm.showFaqModal = false
                  },
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [
      _c("span", [_vm._v("Se precisar fale com a gente "), _c("br")]),
      _c("br"),
      _vm._v("\n          E-mail:\n          "),
      _c(
        "a",
        {
          attrs: {
            href: "mailto:faleconosco@onlypay.com.br",
            target: "_blank",
          },
        },
        [_vm._v("faleconosco@onlypay.com.br")]
      ),
      _c("br"),
      _vm._v("\n          Telefone/WhatsApp: "),
      _c(
        "a",
        {
          attrs: {
            href: "https://api.whatsapp.com/send?phone=551138424693",
            target: "_blank",
          },
        },
        [_vm._v("(11) 3842-4693")]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }